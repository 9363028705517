div.imageBoxContainer {
  margin: 10px;
  padding: 20px;
  border: 1px solid #687372;
  border-radius: 10px;
  background-color: aliceblue;
  width: 250px;
  height: 350px;
}

div.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 800;
}

div.description {
  font-size: 14px;
  font-weight: 400;
}


img.image_box {
  border-radius: 10px;
  width: 200px;
  height: 150px;
  object-fit: cover;
}