.App {
  text-align: center;
  background-color: #E3FBF8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}

.images {
  margin: 10px;
  width: 150px;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 6%;
  object-fit: cover;
}

.logoImage {
  margin: 0px;
  max-width: 300px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 6%;
  object-fit: cover;
}

.banner {
  background-color: #E3FBF8;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.contentBlock {
  margin: 10px;
}

.taglineContainer {
  color: #687372;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-grow: 4;
}

.tagline {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 10px;
}

.headerTitle {
  font-family: Arial;
  font-size: 36px;
  font-weight: 700;
  padding-left: 20px;
}

.subtitle {
  padding: 0px;
  margin: 0px;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #E3FBF8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
