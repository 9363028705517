.squareUpApptsContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 2px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 10px;
    background-color: #ffffff;
}

.instruction {
    font-size: 18px;
    font-weight: 700;
    color: #687372;
}
